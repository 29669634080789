import { Builder, withChildren } from '@builder.io/react';
import ProductListing from '../components/commerce/product/listing/listing';

Builder.registerComponent(withChildren(ProductListing), {
    name: 'Product Listing',
    inputs: [
        {
            name: 'shopifyId',
            type: 'string'
        }
    ]
});