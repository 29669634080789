import { Builder } from '@builder.io/react';
import Btn from '../components/material/btn/btn';

Builder.registerComponent(Btn, {
    name: 'Btn',
    inputs: [
        {
            name: 'label',
            type: 'string',
            defaultValue: 'Click Me',
        },
        {
            name: 'href',
            type: 'string',
            defaultValue: undefined,
        },
        {
            name: 'to',
            type: 'string',
            defaultValue: undefined,
        },
        {
            name: 'icon',
            type: 'string',
            defaultValue: undefined,
        },
        {
            name: 'style',
            type: 'object',
            defaultValue: {
                backgroundColor: '#ffffff',
                color: '#000000',
            },
            subFields: [
                {
                    name: 'backgroundColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'color',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'padding',
                    type: 'string'
                }
            ]
        },
        {
            name: 'labelStyle',
            type: 'object',
            defaultValue: {
                fontSize: '16px',
            },
            subFields: [
                {
                    name: 'fontSize',
                    type: 'string',
                    required: true,
                }
            ]
        }

    ]
});