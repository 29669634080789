import { Builder, withChildren } from '@builder.io/react';
import Card from '../components/material/card/card';

Builder.registerComponent(withChildren(Card), {
    name: 'Card',
    inputs: [
        {
            name: 'backgroundColor',
            type: 'color',
            defaultValue: '#ffffff',
        }
    ]
});