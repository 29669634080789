import { Builder } from '@builder.io/react';
import { Link } from 'gatsby';

Builder.registerComponent(Link, {
    name: 'Gatsby Link',
    inputs: [
        {
            name: 'to',
            type: 'string',
            defaultValue: 'Click Me',
        }
    ]
});