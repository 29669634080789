import { Builder, withChildren } from '@builder.io/react';
import Divet from '../components/material/divet/divet';

Builder.registerComponent(withChildren(Divet), {
    name: 'Divet',
    inputs: [
        {
            name: 'style',
            type: 'object',
            defaultValue: {
                backgroundColor: 'rgba(255, 255, 255, 0.25)'
            },
            subFields: [
                {
                    name: 'backgroundColor',
                    type: 'color',
                    required: true,
                }
            ]
        }
    ]
});