import { Builder, withChildren } from '@builder.io/react';
import CollectionProductListings from '../components/commerce/collection/product-listings';

Builder.registerComponent(withChildren(CollectionProductListings), {
    name: 'Collection Product Listings',
    inputs: [
        {
            name: 'shopifyId',
            type: 'string'
        }
    ]
});