import React from 'react';
import { builder } from '@builder.io/react';
import { BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';

import './card.builder'
import './divet.builder'
import './btn.builder'
import './product-listing.builder'
import './products-context.builder'
import './collections-context.builder'
import './collection-product-listings.builder'
import './link.builder'

builder.init(process.env.GATSBY_BUILDER_IO_API_KEY);

export const builderData = {};

export function PFBuilderComponent(props) {
    return <BuilderComponent data={builderData} {...props} />
}